<template>
    <div style="height: 100%">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <el-page-header @back="$router.go(-1)" content="订单评论">
            </el-page-header>
        </div>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" class="searchbox" :inline="true">
                            <el-form-item>
                                <el-input v-model="keyWord" type="text" size="small" placeholder="输入购买人搜索..."
                                    @keyup.enter.native="search" clearable ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-table :data="tableData" stripe height="calc(100% - 80px)" :default-expand-all="true" style="width: 100%"
                    :header-cell-style="{ background: '#F5F7FC' }">
                    <el-table-column type="expand">
                        <template slot-scope="scope">
                            <div class="flex-start flex-wrap">
                                <el-image hide-on-click-modal :src='httpContent + itemphoto.filePath' preview-teleported
                                    :zoom-rate="1.2" :preview-src-list='[httpContent + itemphoto.filePath]'
                                    v-for="itemphoto in scope.row.photos"
                                    style="width: 100px;height:100px;margin-right: 5px;" fit="cover"></el-image>
                            </div>
                            <p><span class="color-red">{{ scope.row.userName }}：</span>{{ scope.row.content }}</p>
                            <p><span class="color-red" v-if="scope.row.replyContent">
                                    回复：{{ scope.row.userName }}</span>{{ scope.row.replyContent }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orderCommodityDetail.sonOrderNumber" label="订单编号">
                    </el-table-column>
                    <el-table-column prop="orderCommodityDetail.commodity.name" label="商品名称">
                    </el-table-column>
                    <el-table-column prop="orderCommodityDetail.commoditySpecifications.name" label="规格">
                    </el-table-column>
                    <el-table-column label="订单信息">
                        <template slot-scope="scope">
                            <div class="orderprovos-box">
                                ￥{{ scope.row.orderCommodityDetail.actualMoney }}*{{
                                    scope.row.orderCommodityDetail.commodityNum
                                }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="150">
                        <template slot-scope="scope">
                            <span v-if="!scope.row.replyContent" class="primary optionBtn"
                                @click="ReplyBtn(scope.row)">回复</span>
                            <span v-else class="info optionBtn">已回复</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
        <el-dialog title="回复评论" :visible.sync="dialogFormVisible" width="500px">
            <el-form :model="form" ref="form" :rules="rules">
                <el-form-item prop="replyContent">
                    <el-input type="textarea" :autosize="{ minRows: 4 }" v-model="form.replyContent" autocomplete="off">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveBtn('form')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { Order } from "../../../components/HospitalDomain/ShopMall/Order"
import bread from "../../../components/common/breadcrumb"

export default {
    name: "Order",
    components: {
        bread
    },
    data () {
        var order = new Order(this.TokenClient, this.Services.Shopping)
        return {
            OrderDomain: order,
            searchForm: {
                statrTime: '',
                endTime: '',
                keyWord: '',
                datepicker: ''
            },
            httpContent: this.Services.Authorization,
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            keyWord: "",
            pickerOptions0: {
                disabledDate (time) {
                    return time.getTime() > Date.now()
                }
            },
            tableData: [],
            dialogFormVisible: false,
            form: {
                id: "",
                replyContent: ""
            },
            rules: {
                replyContent: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                ]
            }
        }
    },
    mounted () {
        this.getList()
    },
    methods: {
        getList () {
            var _this = this
            _this.tableData = []
            _this.OrderDomain.OrderEvaluateList(_this.keyWord, _this.PageIndex,
                function (data) {
                    _this.tableData = data.data.results
                    _this.PageIndex = data.data.pageIndex
                    _this.PageSize = data.data.pageSize
                    _this.PageTotal = data.data.pageCount
                    _this.DataTotal = data.data.dataTotal
                },
                function (error) {
                    console.log(error)
                })
        },
        search () {
            this.PageIndex = 1
            this.getList()
        },
        ChangePage (pageIndex) {
            this.PageIndex = pageIndex
            this.getList()
        },
        ReplyBtn (item) {
            this.form.id = item.id
            this.dialogFormVisible = true
        },
        saveBtn (formName) {
            var _this = this
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    _this.OrderDomain.OrderEvaluateReplay(_this.form,
                        function (data) {
                            _this.dialogFormVisible = false
                            _this.$refs[formName].resetFields()
                            _this.PageIndex = 1
                            _this.getList()

                        },
                        function (err) {
                            console.log(err)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })

        },
    }
}
</script>

<style scoped>
/*content*/


.table-top {
    margin-bottom: -10px;
}

.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.iq-search-bar .searchbox {
    width: 480px;
    position: relative;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 100%;
    padding: 5px 40px 5px 15px;
    border-radius: 10px;
    border: none;
    background: #ceebee;
    line-height: 1.8;
}

.color-red {
    color: red;
    margin-right: 20px;
}

.iq-search-bar .searchbox .el-input {
    width: 300px;
}
</style>


